.success_order {
  text-align: center;
  padding: 40px 0;
  background: #EBF0F5;
}

.success_order h1 {
  color: #88B04B;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}

.success_order p {
  color: #404F5E;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin: 0;
}

.success_order i {
  color: #9ABC66;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}

.success_order .cardOd {
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #C8D0D8;
  display: inline-block;
  margin: 0 auto;
}

.success_order .cardData {
  border-radius: 200px;
  height: 200px;
  width: 200px;
  background: #F8FAF5;
  margin: 0 auto;
}