.table-cart td:hover {
  cursor: pointer;
  color: #4CAD47;
  text-decoration: none;
  outline: 0px;
}

.image-scrollbarUpdateProduct {
  display: flex;
  max-width: 620px;
  overflow: auto;
  padding-top: 9px;
}

@media (max-width:1199px) {
  .image-scrollbarUpdateProduct {
    max-width: 465px;
  }

}

@media (max-width:991px) {
  .image-scrollbarUpdateProduct {
    max-width: 310px;
  }
}


@media (max-width:767px) {

  .image-scrollbarUpdateProduct {
    flex-wrap: wrap;
    max-width: 100%;
    margin-bottom: 12px;
  }

  .cartImagesBlock {
    flex-direction: column;
    justify-content: flex-start !important;

  }

  .image-preview .upload-img {
    width: 142px !important;
    margin-bottom: 13px;
  }
}

/* couponCard_____________________ */
.couponCard {
  width: 240px;
  height: 140px;
  border-radius: 5px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  padding: 10px 10px;
  position: relative;
}

.couponMain,
.couponCopy-button {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
}

.couponCard::after {
  position: absolute;
  content: "";
  height: 40px;
  right: -30px;
  border-radius: 40px;
  z-index: 1;
  top: 50px;
  background-color: #F3F3F3;
  width: 40px;
}

.couponCard::before {
  position: absolute;
  content: "";
  height: 40px;
  left: -30px;
  border-radius: 40px;
  z-index: 1;
  top: 50px;
  background-color: #F3F3F3;
  width: 40px;
}

.co-img img {
  width: 45px;
  height: 45px;
}

.couponCardVertical {
  border-left: 2px dotted black;
  height: 75px;
  position: absolute;
  left: 30%;
}

.couponCardContent h1 {
  font-size: 24px;
  margin-left: -12px;
  color: #565656;
  font-weight: 600;
}

.couponCardContent h1 span {
  font-size: 18px;
}

.couponCardContent h2 {
  font-size: 14px;
  margin-left: -12px;
  color: #565656;
  text-transform: uppercase;
  font-weight: 500;

}

.couponCardContent p {
  font-size: 12px;
  color: #696969;
  margin-left: -12px;
}

.copy-button {
  margin: 4px 0 0px 0;
  height: 40px;
  border-radius: 4px;
  padding: 0 5px;
  border: 1px solid #e1e1e1;
}

.copy-button input {
  width: 74%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 15px;

}

.copy-button button {
  padding: 2px 5px;
  height: 30px;
  background-color: #dc143c;
  color: #fff;
  /* border: 1px solid transparent; */
  /* border: 1px solid #1b05c1; */
  font-size: 15px;
}

.buy {
  position: absolute;
  content: "";
  bottom: 20px;
  left: 20px;
  background-color: #dc143c;
}

/* couponCard_____________________ */
/* .table-cart td::after {
  content: "Update Product";
  padding: 2px;
  display: none;
  position: relative;
  top: -20px;
  right: -30px;
  width: 150px;
  text-align: center;
  background-color: #fef4c5;
  border: 1px solid #d4b943;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
}

.table-cart td:hover::after {
  display: block;
} */

/* proofOfProPlanModal */
.proofOfProPlanModal {
  text-align: center;
}

.proofOfProPlanModal .form-select {
  color: #000;
  font-size: 16px;
  padding: 15px 20px;
  background-color: #FFFFFF;
  border: 1px solid #DBDBDB;
  border-radius: 100px;
  background-position: right 20px center;
}

.proofOfProPlanModal .select-box {
  width: 70%;
  margin: 0 auto 20px;
}

.upload-box {
  font-size: 16px;
  width: 300px;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.upload-box>div {
  display: flex;
  align-items: center;
  background: rgb(77 173 77 / 30%);
  padding: 10px;
  margin-bottom: 16px;
  border-radius: 8px;
}

.upload-box>div:last-child {
  margin-bottom: 0;
}

.upload-box>div>div {
  flex-grow: 1;
}


.upload-box>div img {
  width: 36px;
  margin-right: 10px;
}

.upload-box>div .d-flex {
  flex-wrap: wrap;
  /* margin-bottom: 10px; */
  justify-content: space-between;
  position: relative;
}

.upload-box p {
  margin-bottom: 6px;
}


.upload-box p:first-child {
  margin-right: 6px;
}

.upload-box .check {
  width: 16px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: none;
  margin: 0;
}

.upload-box .progress {
  height: 6px;
}

.upload-box .progress-bar {
  background: #4CAD47;
}

.upload-box>div.uploaded .d-flex {
  flex-direction: column;
  padding-right: 24px;
}

.upload-box>div.uploaded .d-flex p {
  margin: 0;
}

.upload-box>div.uploaded .d-flex p:nth-child(2) {
  font-size: 10px;
  line-height: 1.2;
}

.upload-box>div.uploaded .check {
  display: block;
}

.upload-item {
  padding: 10px 0;

}

.cartImages-protectionPlanModal {
  margin: 20px 0 28px;
}

.btn.btn-noHover {
  color: #fff !important;
}

.btn.btn-noHover:before {
  display: none;
}

.btn.btn-noHover:hover {
  color: #fff;
}


.signatureCanvasContainer {
  border: 2px solid #227e28;
  width: 298px;
  height: 98px;
  margin: 0 auto 30px;
}

.signatureCanvasContainerOptionOne {
  border: 2px solid #227e28;
  width: 300px;
  height: 100px;
  margin: 10px
}

.signatureCanvasContainerEdit {
  width: 300px;
  height: 100px;
  margin: 10px
}

.green-text {
  color: green;
}

.red-text {
  color: rgb(128, 0, 0);
}

.yello-text {
  color: rgb(182, 142, 0);
}