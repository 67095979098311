.specificationList__items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  gap: 1rem;
}

.specificationList_Inside,
.specificationList_Inside--hideOnMobile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: calc(50% - 0.5rem);
}

.specificationList__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0;
  margin-right: 0.5rem;
  background-color: #4CAD47;
  color: white;
  border-radius: 50%;
  font-weight: bold;
}

.specificationList__text {
  font-weight: var(--fontLaborReg);
  font-family: var(--fontLabor);
  font-size: 1rem;
  line-height: 1.5rem;
  text-decoration: none;

}