.error {
    border: 1px solid #ff0000c1;; /* Red border for error state */
  }

  .error-text {
    color: #ff0000c1;
  }

  .register-block 
  .error-text {
    color: #ff0000c1;
    font-weight: 400;
    margin-left: 26px;
    font-size: 16px;
  }

  .form-control-error{
    border: 1px solid #ff0000c1 !important;
    border-radius: 40px;
}
.form-control-success{
    border: 1px solid #DBDBDB !important;
}