.container {
  width: auto;
}

.task-progress-header {
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  padding: 15px;
  margin-left: 20px;
}

a {
  text-decoration: none;
  display: block;
}

.progressbar li img {
  width: 15px;
}

.progressbar {
  counter-reset: step;
}

.progressbar li {
  list-style-type: none;
  width: 11%;
  float: left;
  font-size: 10px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #7d7d7d;
}

.progressbar li:before {
  width: 30px;
  height: 30px;
  content: counter(step);
  counter-increment: step;
  line-height: 30px;
  border: 2px solid #7d7d7d;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: white;
}

.progressbar li:after {
  width: 100%;
  height: 2px;
  content: '';
  position: absolute;
  background-color: #7d7d7d;
  top: 15px;
  left: -50%;
  z-index: -1;
}

.progressbar li:first-child:after {
  content: none;
}

.progressbar li.active {
  color: green;
}

.progressbar li.active:before {
  border-color: #55b776;
}

.progressbar li.active+li:after {
  background-color: #55b776;
}

@media (max-width: 767px) {
  .progressbar li {
    list-style-type: none;
    width: 12%;
    float: left;
    font-size: 10px;
    position: relative;
    text-align: center;
    text-transform: capitalize;
    color: #7d7d7d;
  }
}

@media (max-width: 400px) {
  .progressbar > li a {  
    display: none!important;
  }
}
